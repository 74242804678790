import * as React from 'react';

export function LinkButton({
    children,
    onClick,
}: {
    children: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
    return (
        <button className="text-blue-800 cursor-pointer underline" onClick={onClick}>
            {children}
        </button>
    );
}
