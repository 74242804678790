import { FaBars } from 'react-icons/fa';

export default function MenuController({ onToggle }: { onToggle: () => void }) {
    return (
        <div className="leaflet-left leaflet-top top-2 left-2">
            <div className="leaflet-control leaflet-bar w-10 h-10 p-0 bg-white flex items-center justify-center" onClick={onToggle}>
                <FaBars className="w-6 h-6" />
            </div>
        </div>
    );
}
