import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { Store } from './Store';
import { SupabaseAuthProvider } from './SupabaseAuthProvider';
import { Instant, ZoneId } from '@js-joda/core';

const store = new Store(new SupabaseAuthProvider());

navigator.geolocation.watchPosition((pos) => {
    store.setCoords(pos.coords, Instant.ofEpochMilli(pos.timestamp).atZone(ZoneId.UTC));
});

ReactDOM.render(
    <React.StrictMode>
        <App store={store} />
    </React.StrictMode>,
    document.getElementById('root'),
);
