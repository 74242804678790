import MyLocation from './MyLocation.svg'

export default function LocateController({ onClick }: { onClick: () => void }) {
    return (
        <div className="leaflet-left leaflet-top top-16 left-2">
            <div className="leaflet-control leaflet-bar p-0 w-10 h-10 bg-white mt-8 flex items-center justify-center" onClick={onClick}>
                <img src={MyLocation} style={{"width": "29px", "height": "29px"}} />
            </div>
        </div>
    );
}
