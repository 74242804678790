import { AuthProvider, PendingUser, User } from './AuthProvider';
import { supabase } from './supabase';

type UserChangeCallback = (user: User | PendingUser | null) => void;
export class SupabaseAuthProvider implements AuthProvider {
    userChangeCallbacks: UserChangeCallback[] = [];

    constructor() {
        supabase.auth.onAuthStateChange((_, s) => {
            const email = s?.user?.email;

            if (email !== undefined) {
                this.userChangeCallbacks.forEach((cb) => {
                    cb({ email });
                });
            }
        });
    }

    signIn(email: string): void {
        supabase.auth.signIn({
            email: email,
        });

        this.userChangeCallbacks.forEach((cb) => {
            cb({ pending: true });
        });
    }

    signOut(): Promise<void> {
        return supabase.auth.signOut().then((x) => {
            if (x.error !== null) {
                console.debug('Signing out failed', x);
                throw new Error('Signing out failed');
            } else {
                this.userChangeCallbacks.forEach((cb) => {
                    cb(null);
                });
                return;
            }
        });
    }

    initialUser(): User | null {
        const supabaseUser = supabase.auth.user();

        if (supabaseUser?.email) {
            return { email: supabaseUser.email };
        } else return null;
    }

    onUserChange(callback: UserChangeCallback): void {
        this.userChangeCallbacks.push(callback);
    }
}
