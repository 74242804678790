import { ToggleMenu, LatLng, SetMapCenter } from './Store';
import { Map as LMap, Control, CircleMarker } from 'leaflet';
import * as Tangram from 'tangram';
import { MapContainer as JSMapContainer, useMap } from 'react-leaflet';
import MenuController from './MenuController';
import LocateController from './LocateController';
import 'leaflet/dist/leaflet.css';
import classNames from 'classnames';
import { useLayoutEffect, useState } from 'react';
import { useLongPress } from 'use-long-press';

export function Map({
    isMenuToggled,
    mapCoords: coords,
    userCoords,
    store,
}: {
    isMenuToggled: boolean;
    mapCoords: LatLng | null;
    userCoords: LatLng | null;
    store: ToggleMenu & SetMapCenter;
}) {
    const [map, setMap] = useState<LMap | null>(null);

    const bind = useLongPress((ev) => {
        const anyEv = ev as any;

        if (map !== null) {
            const latLng = map.layerPointToLatLng([anyEv.pageX, anyEv.pageY]);
            const url = `https://snowmat.es/#${latLng.lat}/${latLng.lng}`;

            if (navigator.share) {
                navigator.share({
                    url
                });
            } else {
                console.log(url);
            }
        }
    }, {
        cancelOnMovement: true,
        threshold: 1000,
        captureEvent: true
    });

    const layer = Tangram.leafletLayer({
        scene: 'style.yaml',
    });

    const whenCreated = (map: LMap) => {
        setMap(map);
        layer.addTo(map);
        if (process.env.NODE_ENV === 'development') {
            map.addEventListener('move', (ev) => {
                console.debug({
                    lat: map.getCenter().lat,
                    lng: map.getCenter().lng,
                    zoom: map.getZoom(),
                });
            });
        }
    };

    const MapContainer = JSMapContainer as (_: any) => JSX.Element;

    useLayoutEffect(() => {
        map?.invalidateSize();
    });

    return (
        <div
            className={classNames({
                relative: true,
                'w-full': true,
                'h-full': true,
            })} {...bind}
        >
            <MapContainer
                center={[46.004, 6.696]}
                zoom={14}
                minZoom={12}
                whenCreated={whenCreated}
                zoomControl={false}
                className="w-full h-full"
            >
                <DrawUserWhenUserCoordsChange coords={userCoords} />
                <SetMapWhenLatLngChanges coords={coords} />
                <MenuController onToggle={store.toggleMenu.bind(store)}></MenuController>
                <LocateController onClick={() => userCoords ? store.setMapCenter(userCoords) : null}></LocateController>
            </MapContainer>
        </div>
    );
}

function SetMapWhenLatLngChanges({ coords }: { coords: LatLng | null }) {
    const map = useMap();
    const [lastCoords, setLastCoords] = useState<LatLng | null>(null);

    const mapDifferent = map.getCenter().lat !== coords?.lat || map.getCenter().lng !== coords?.lng;
    const coordsHaveChanged =
        (lastCoords == null && coords !== null) ||
        (lastCoords !== null && !coords?.equals(lastCoords));

    if (mapDifferent && coordsHaveChanged) {
        if (coords) {
            map.setView([coords.lat, coords.lng], 14);
            setLastCoords(coords);
        }
    }

    return null;
}

function DrawUserWhenUserCoordsChange({ coords } : { coords: LatLng | null } ) {
    const map = useMap();

    const marker = ((map as any).markerLayer as CircleMarker | null);
    marker?.remove();

    if (coords != null) {
        const marker = new CircleMarker([coords.lat, coords.lng], {
            fillColor: "#2563eb", // blue-600
            fillOpacity: 1,
            color: "white",
            weight: 3,
            radius: 8
        });

        map.addLayer(marker);
        (map as any).markerLayer = marker;
    }

    return null;
}
