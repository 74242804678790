import React, { useState, useEffect } from 'react';

function useSize(): {height: number, width: number} {
    const [size, setSize] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    React.useEffect(() => {
        function handleResize() {
            setSize({
              height: window.innerHeight,
              width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    });

    return size;
}

export function useIsLargeWidth() {
    const {height, width} = useSize();

    return width >= 1024;
}
