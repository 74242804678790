import classNames from 'classnames';
import { useState } from 'react';
import { UserStore } from './Store';
import { LinkButton } from './LinkButton';
import { PendingUser, User } from './AuthProvider';
import { Emoji } from './Emoji';

export function LoginPrompt({ user, store }: { user: User | PendingUser | null; store: UserStore }) {
    const [state, setState] = useState<'prompt' | 'clicked'>('prompt');
    const classes = classNames({
        'w-full pl-4 pr-4 h-16 flex items-center justify-center': true,
        hidden: User.isLoggedIn(user),
    });
    const elem =
        state === 'prompt' ? (
            <LinkButton onClick={() => setState('clicked')}>
                Sign in to see your friends on the map&nbsp;<Emoji>{'\u{1f46c}'}</Emoji>
            </LinkButton>
        ) : user && 'pending' in user ? (
            <>
                Check your email for a sign in link&nbsp;<Emoji>{'\u{1f4e9}'}</Emoji>
            </>
        ) : (
            <LoginForm store={store} />
        );

    return <div className={classes}>{elem}</div>;
}

function LoginForm({ store }: { store: UserStore }) {
    const [email, setEmail] = useState<string>('');
    return (
        <form className="inline">
            <input
                type="email"
                placeholder="Enter your email here"
                className="p-1 mr-2"
                onChange={(e) => setEmail(e.target.value)}
            />
            <input
                type="submit"
                className="bg-slate-300 p-1 pl-2 pr-2 font-bold rounded-md text-gray-800"
                onClick={(e) => {
                    store.signIn(email);
                    e.preventDefault();
                }}
            />
        </form>
    );
}
