import React from 'react';
import { LatLng, State, Store } from './Store';
import { Map } from './Map';
import { SidebarMenu } from './SidebarMenu';
import { LoginPrompt } from './LoginPrompt';
import { User } from './AuthProvider';

function App({ store }: { store: Store }) {
    const [state, setState] = React.useState<State>();
    React.useEffect(() => {
        store.stateObservable().subscribe((x) => setState(x));
    }, [store]);

    if (state !== undefined) {
        const userCoords = state.userCoords !== null ?
            new LatLng(state.userCoords.coords.latitude, state.userCoords.coords.longitude) : null;

        return (
            <div className="h-full flex flex-col">
                <div className="grow flex flex-row h-0">
                    <SidebarMenu
                        isMenuToggled={state.menuToggled}
                        store={store}
                        user={User.loggedInUser(state.user)} />
                    <Map
                        isMenuToggled={state.menuToggled}
                        mapCoords={state.mapCoords}
                        userCoords={userCoords}
                        store={store} />
                </div>
                <div className="flex-none">
                    <LoginPrompt user={state.user} store={store} />
                </div>
            </div>
        );
    } else {
        return <></>;
    }
}

export default App;
