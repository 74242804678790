export type User = {
    email: string;
};

export const User = {
    isLoggedIn(user: User | PendingUser | null): boolean {
        return user !== null && 'email' in user;
    },

    loggedInUser(user: User | PendingUser | null): User | null {
        if (user && 'email' in user) {
            return user;
        } else if (!user) {
            return user;
        } else {
            return null;
        }
    },
};

export type PendingUser = { pending: true };

export interface AuthProvider {
    signOut(): Promise<void>;
    signIn(email: string): void;
    initialUser(): User | null;
    onUserChange(callback: (user: User | PendingUser | null) => void): void;
}
