import React from 'react';

export function Emoji({
    children
}: {
    children: React.ReactNode;
}) {
    return (
        <span style={{fontFamily: "apple color emoji, segoe ui emoji, noto color emoji, android emoji, emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol"}}>
            {children}
        </span>
    )
}
