import { ZonedDateTime } from '@js-joda/core';
import { otherwise } from 'ramda';
import { BehaviorSubject, Observable } from 'rxjs';
import { User, PendingUser, AuthProvider } from './AuthProvider';

export class LatLng {
    public readonly lat: number;
    public readonly lng: number;

    constructor(lat: number, lng: number) {
        this.lat = lat;
        this.lng = lng;
    }

    public equals(other: LatLng): boolean {
        return this.lat == other.lat && this.lng == other.lng;
    }
}


export type TimestampedCoords = {
    coords: GeolocationCoordinates;
    timestamp: ZonedDateTime;
};

export class State {
    public readonly menuToggled: boolean;
    public readonly mapCoords: LatLng | null;
    public readonly user: User | PendingUser | null;
    public readonly userCoords: TimestampedCoords | null;

    private constructor(o: { menuToggled: boolean; mapCoords: LatLng | null; user: User | PendingUser | null, coords: TimestampedCoords | null }) {
        this.menuToggled = o.menuToggled;
        this.mapCoords = o.mapCoords;
        this.user = o.user;
        this.userCoords = o.coords;
    }

    static initial(userState: User | PendingUser | null): State {
        return new State({
            menuToggled: false,
            mapCoords: null,
            user: userState,
            coords: null
        });
    }
}

export interface ToggleMenu {
    toggleMenu(): void;
}

export interface SetMapCenter {
    setMapCenter(coords: LatLng): void;
}

export interface UserStore {
    signIn(email: String): void;
    signOut(): void;
}

export class Store implements ToggleMenu, SetMapCenter, UserStore {
    authProvider: AuthProvider;
    subject: BehaviorSubject<State>;

    constructor(authProvider: AuthProvider) {
        this.authProvider = authProvider;
        this.subject = new BehaviorSubject<State>(State.initial(authProvider.initialUser()));

        authProvider.onUserChange((user) => {
            this.setUser(user);
        });

        if (process.env.NODE_ENV === 'development') {
            this.subject.subscribe((x) => console.debug(x));
        }
    }

    public stateObservable(): Observable<State> {
        return this.subject;
    }

    public toggleMenu(): void {
        this.subject.next({
            ...this.subject.value,
            menuToggled: !this.subject.value.menuToggled,
        });
    }

    public setMapCenter(coords: LatLng): void {
        this.subject.next({
            ...this.subject.value,
            mapCoords: coords,
        });
    }

    public setUser(user: User | PendingUser | null) {
        this.subject.next({
            ...this.subject.value,
            user: user,
        });
    }

    public setCoords(coords: GeolocationCoordinates, timestamp: ZonedDateTime) {
        this.subject.next({
            ...this.subject.value,
            userCoords: {coords, timestamp}
        });
    }

    public signIn(email: string) {
        this.authProvider.signIn(email);
    }

    public signOut(): Promise<void> {
        return this.authProvider.signOut();
    }
}
