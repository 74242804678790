import classNames from 'classnames';
import resorts from './resorts.json';
import { LatLng, SetMapCenter, ToggleMenu, UserStore } from './Store';
import { List } from 'immutable';
// @ts-ignore
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { User } from './AuthProvider';
import { LinkButton } from './LinkButton';
import * as R from 'ramda';
import { useIsLargeWidth } from './hooks';

type JsonResort = typeof resorts['France'][0];

export function SidebarMenu({
    store,
    isMenuToggled,
    user,
}: {
    store: SetMapCenter & ToggleMenu & UserStore;
    isMenuToggled: boolean;
    user: User | null;
}) {
    const names = classNames({
        'overflow-y-scroll h-full flex flex-col shrink-0': true,
        'w-0 p-0': !isMenuToggled,
        'w-full lg:w-1/4 p-4': isMenuToggled,
    });

    return (
        <div className={names}>
            <div className="flex flex-col flex-auto">
                <div className="flex-none">
                    {List(Object.keys(resorts))
                        .sort()
                        .map((x) => (
                            <ResortList store={store} countryName={x} resorts={(resorts as any)[x] as JsonResort[]} />
                        ))}
                </div>
                <div className="flex-auto"></div>
                <div className="flex-none">
                    <Footer store={store} user={user} />
                </div>
            </div>
        </div>
    );
}

function ResortList({
    store,
    countryName,
    resorts,
}: {
    store: SetMapCenter & ToggleMenu;
    countryName: string;
    resorts: JsonResort[];
}) {
    const flag = getUnicodeFlagIcon(resorts[0].countryCode) as string;

    const sortedResorts = List(resorts)
        .sortBy((x) => x.label)
        .toJSON();

    return (
        <>
            <h3 className="font-bold text-lg text-gray-800">
                {flag} {countryName}
            </h3>
            <ul>
                {sortedResorts.map((resort) => {
                    return <Resort store={store} resort={resort} />;
                })}
            </ul>
        </>
    );
}

function Resort({ store, resort }: { store: SetMapCenter & ToggleMenu; resort: JsonResort }) {
    const onClick = (ev: any) => {
        store.toggleMenu();
        store.setMapCenter(new LatLng(
            Number(resort.lat).valueOf(),
            Number(resort.lng).valueOf(),
        ));
    };
    return (
        <li className="ml-4">
            <button className="text-blue-800 cursor-pointer underline" onClick={onClick}>
                {resort.label}
            </button>
        </li>
    );
}

function Footer({ store, user }: { store: UserStore & ToggleMenu; user: User | null }) {
    const isLargeWidth = useIsLargeWidth();

    var links = [];
    var userMessage = null;

    if (user) {
        userMessage = (
            <>
                Logged in as <strong>{user.email}</strong>
            </>
        );

        links.push(<LinkButton onClick={() => store.signOut()}>Sign out</LinkButton>);
    }

    if (!isLargeWidth) {
        links.push(<LinkButton onClick={() => store.toggleMenu()}>Toggle menu</LinkButton>);
    }

    if (links.length === 0) {
        return null;
    } else {
        if (isLargeWidth) {
            links.unshift(userMessage);
            const interspersed = R.intersperse(<>&nbsp;&middot;&nbsp;</>, links);

            return (
                <p className="mt-4 flex justify-center">
                    <span>{interspersed}</span>
                </p>
            );
        } else {
            const interspersed = R.intersperse(<>&nbsp;&middot;&nbsp;</>, links);

            return (
                <p className="mt-4 flex flex-col items-center">
                    <div>{userMessage}</div>
                    <div>{interspersed}</div>
                </p>
            );
        }
    }
}
